const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Název online reportáže'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Název online reportáže'
  },
  articleSelectReportage: {
    reportageId: 'ID',
    reportageTitle: 'Online reportáž',
    selectReportageButton: 'Vyberte Online reportáž',
    selectModal: {
      title: 'Vyberte Online reportáž'
    },
    confirmModal: {
      title: 'Vložení Online reportáže',
      body1: 'Chystáte se vložit <b>{title}.</b>',
      body2: 'Chcete přepsat stávájící <b><i>titulek</i></b> a <b><i>perex</i></b> článku <b>Názvem a popisem online reportáže?</b>'
    }
  },
  saveReportageFirst: 'Nejprve uložte reportáž',
  notOpenReportage: 'Příspěvky lze přidávat pouze do Online reportáže ve stavu "Otevřená"',
  server: 'Server',
  propertiesTab: 'Vlastnosti reportáže',
  manageContributionsTab: 'Spravovat příspěvky',
  title: 'Název reportáže',
  position: 'Pozice',
  startedAt: 'Začátek události',
  isOpenLabel: 'Stav reportáže',
  isOpenLabelTrue: 'Otevřená',
  isOpenLabelFalse: 'Zavřená',
  content: 'Text příspěvku',
  contentShort: 'Text',
  timestamp: 'Datum a čas příspěvku',
  timestampShort: 'Datum/čas',
  filterSelect: 'Filtr',
  description: 'Popis',
  importantLabel: 'Důležité',
  importantLabelTrue: '',
  importantLabelFalse: '',
  imageReportage: 'Obrázek reportáže',
  imageContribution: 'Obrázek příspěvku'
}

export default messages
