import CoreApi from '@/api/core'
import OnlineReportageFilterService from '@/services/onlineReportage/OnlineReportageFilterService'
import { callApi, createCrudStore } from '@/store/modules/simpleCrudStore'

const API_NAME = '/reportage'
const API_NAME_REPORTAGE_CONTRIBUTION = '/reportageContribution'
const API_NAME_REPORTAGE_FILTER_CATEGORY = '/reportageFilterCategory'

const filterModel = {
  id: '',
  title: ''
}
const store = createCrudStore({
  apiName: API_NAME,
  filterModel,
  apiInstanceCallback: () => CoreApi(),
  buildFilterQueryCallback: OnlineReportageFilterService.buildFilterQuery
})

store.state = {
  ...store.state,
  filterCategory: []
}

store.mutations = {
  ...store.mutations,
  storeFilterCategory (state, responseData) {
    state.filterCategory = responseData
  }
}

store.actions = {
  ...store.actions,
  async create (store, record) {
    return callApi(store, async () => {
      const response = await CoreApi().post(API_NAME, record)
      return response.data
    })
  },
  async update (store, record) {
    return callApi(store, async () => {
      const url = `${API_NAME}/${record.id}`
      const response = await CoreApi().put(url, record)
      return response.data
    })
  },
  async createContribution (store, { reportage, contribution }) {
    return callApi(store, async () => {
      await CoreApi().post(API_NAME_REPORTAGE_CONTRIBUTION, { ...contribution, reportageId: reportage.id })
    })
  },
  async updateContribution (store, { contribution }) {
    return callApi(store, async () => {
      const url = `${API_NAME_REPORTAGE_CONTRIBUTION}/${contribution.id}`
      await CoreApi().put(url, contribution)
    })
  },
  async deleteContribution (store, contribution) {
    return callApi(store, async () => {
      const url = `${API_NAME_REPORTAGE_CONTRIBUTION}/${contribution.id}`
      await CoreApi().delete(url)
    })
  },
  async fetchFilterCategory (store) {
    return callApi(store, async () => {
      const response = await CoreApi().get(API_NAME_REPORTAGE_FILTER_CATEGORY + '?offset=0&limit=100')
      store.commit('storeFilterCategory', response.data)
      return response.data
    })
  }
}

store.getters = {
  ...store.getters,
  filterCategory (state) {
    return state.filterCategory
  }
}

export default store
