import _ from 'lodash'
import config from '@/config'
import NotifyService from '@/services/NotifyService'

export const callApi = async (store, callback) => {
  try {
    store.commit('TOGGLE_LOADING', true, { root: true })
    store.commit('setError', null)
    return await callback()
  } catch (error) {
    console.error(error)
    store.commit('setError', error)
    NotifyService.setErrorMessage(error)
    return null
  } finally {
    store.commit('TOGGLE_LOADING', false, { root: true })
  }
}

export const createCrudStore = ({ apiInstanceCallback, apiName, filterModel, buildFilterQueryCallback }) => {
  const state = {
    list: [],
    detail: null,
    totalCount: 0,
    page: 1,
    filter: _.cloneDeep(filterModel),
    error: null
  }

  const mutations = {
    storeList (state, responseData) {
      state.list = responseData.data
      state.totalCount = responseData.totalCount
    },
    storeDetail (state, item) {
      state.detail = item
    },
    setPage (state, page) {
      state.page = page
    },
    setError (state, error) {
      state.error = error
    },
    setFilter (state, filter) {
      state.filter = filter
    },
    resetFilter (state) {
      state.filter = _.cloneDeep(filterModel)
    }
  }

  const actions = {
    async fetch (store) {
      return callApi(store, async () => {
        const { limit } = config.defaults.list
        const offset = (store.state.page * limit) - limit
        const filter = buildFilterQueryCallback(store.state.filter)
        const url = `${apiName}?limit=${limit}&offset=${offset}${filter}&order[id]=desc`
        const response = await apiInstanceCallback().get(url)
        store.commit('storeList', response.data)
        return response.data
      })
    },
    async fetchOne (store, id) {
      return callApi(store, async () => {
        const url = `${apiName}/${id}`
        const response = await apiInstanceCallback().get(url)
        store.commit('storeDetail', response.data)
        return response.data
      })
    },
    async create (store, record) {
      return callApi(store, async () => {
        const response = await apiInstanceCallback().post(apiName, record)
        store.commit('storeDetail', response.data)
        return response.data
      })
    },
    async update (store, record) {
      return callApi(store, async () => {
        const url = `${apiName}/${record.id}`
        const response = await apiInstanceCallback().put(url, record)
        store.commit('storeDetail', response.data)
        return response.data
      })
    },
    async deleteRecord (store, { id }) {
      await callApi(store, async () => {
        const url = `${apiName}/${id}`
        await apiInstanceCallback().delete(url)
      })
    }
  }

  const getters = {
    detail (state) {
      return state.detail
    },
    list (state) {
      return state.list
    },
    totalCount (state) {
      return state.totalCount
    },
    page (state) {
      return state.page
    },
    error (state) {
      return state.error
    },
    filter (state) {
      return state.filter
    }
  }

  return {
    namespaced: true,
    state,
    mutations,
    actions,
    getters
  }
}
