const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Názov online reportáže'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Názov online reportáže'
  },
  articleSelectReportage: {
    reportageId: 'ID',
    reportageTitle: 'Online reportáž',
    selectReportageButton: 'Vyber Online reportáž',
    selectModal: {
      title: 'Vyber Online reportáž'
    },
    confirmModal: {
      title: 'Vloženie Online reportáže',
      body1: 'Chystáte sa vložiť <b>{title}.</b>',
      body2: 'Chcete prepísať existujúci <b><i>titulok</i></b> a <b><i>perex</i></b> článku <b>Názvom a popisom online reportáže?</b >'
    }
  },
  saveReportageFirst: 'Najprv uložte reportáž',
  notOpenReportage: 'Príspevky je možné pridávať iba do Online reportáže v stave "Otvorená"',
  server: 'Server',
  propertiesTab: 'Vlastnosti reportáže',
  manageContributionsTab: 'Spravovať príspevky',
  title: 'Názov reportáže',
  position: 'Pozícia',
  startedAt: 'Začiatok udalosti',
  isOpenLabel: 'Stav reportáže',
  isOpenLabelTrue: 'Otvorená',
  isOpenLabelFalse: 'Zatvorená',
  content: 'Text príspevku',
  contentShort: 'Text',
  timestamp: 'Dátum a čas príspevku',
  timestampShort: 'Dátum/čas',
  filterSelect: 'Filter',
  description: 'Popis',
  importantLabel: 'Dôležité',
  importantLabelTrue: '',
  importantLabelFalse: '',
  imageReportage: 'Obrázok reportáže',
  imageContribution: 'Obrázok príspevku'
}

export default messages
