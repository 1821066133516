const messages = {
  home: 'Home',
  article_list: 'List of articles',
  article_detail: 'Article detail',
  article_edit: 'Article edit',
  articleByDocumentId: 'Article detail',
  author_list: 'Author list',
  author_detail: 'Author detail',
  author_edit: 'Author edit',
  author_new: 'Author new',
  breakingnews_edit: 'Edit news',
  breakingnews_list: 'Breaking news',
  breakingnews_new: 'Create news',
  source_list: 'Source list',
  source_detail: 'Source detail',
  source_edit: 'Source edit',
  source_new: 'Source new',
  otherFunction_list: 'Other function list',
  otherFunction_detail: 'Other function detail',
  otherFunction_edit: 'Other function edit',
  otherFunction_new: 'Other function new',
  contentBlock_list: 'Content block list',
  contentBlock_detail: 'Content block detail',
  contentBlock_edit: 'Content block edit',
  contentBlock_new: 'Content block new',
  pollBlock_list: 'Poll block list',
  pollBlock_detail: 'Poll block detail',
  pollBlock_edit: 'Poll block edit',
  pollBlock_new: 'Poll block new',
  topic_list: 'Topic list',
  topic_detail: 'Topic detail',
  topic_edit: 'Topic edit',
  topic_new: 'Topic new',
  car_list: 'Car list',
  car_detail: 'Car detail',
  car_edit: 'Car edit',
  car_new: 'Car new',
  restaurant_list: 'Restaurant list',
  restaurant_detail: 'Restaurant detail',
  restaurant_edit: 'Restaurant edit',
  restaurant_new: 'Restaurant new',
  hotel_list: 'Hotel list',
  hotel_detail: 'Hotel detail',
  hotel_edit: 'Hotel edit',
  hotel_new: 'Hotel new',
  contentBlockItem_list: 'HP management',
  contentBlockItem_list_old: 'HP management(old)',
  dam_list: 'Dam images list',
  dam_pdf_list: 'Dam documents list',
  native_campaign_list: 'Overview of launched campaigns',
  native_campaign_detail: 'Campaign Detail',
  native_campaign_edit: 'Edit Campaign',
  native_campaign_duplicate: 'Duplicate Campaign',
  native_campaign_from_article: 'Create Campaign from an article',
  native_campaign_new: 'Create Campaign',
  don_npa_list: 'Campaign Management',
  don_npa_predictions: 'Campaign Progress',
  don_npa_detail: 'Campaign Detail',
  don_npa_edit: 'Edit Campaign',
  don_npa_duplicate: 'Duplicate Campaign',
  don_npa_new: 'Create Campaign',
  don_npa_config: 'DON Box Configuration',
  dashboard: 'Overview of environment',
  dashboard_old: 'Overview of environment (old)',
  welcome: 'Welcome',
  infobox_list: 'Infobox list',
  infobox_detail: 'Infobox detail',
  infobox_edit: 'Infobox edit',
  infobox_new: 'Infobox new',
  joke_list: 'Joke list',
  joke_detail: 'Joke detail',
  joke_edit: 'Joke edit',
  joke_new: 'Joke new',
  quote_list: 'Quote list',
  quote_detail: 'Quote detail',
  quote_edit: 'Quote edit',
  quote_new: 'Quote new',
  poll_list: 'Poll list',
  poll_detail: 'Poll detail',
  poll_edit: 'Poll edit',
  poll_new: 'Poll new',
  poll_management: 'Polls on homepage',
  likeDislike_list: 'Like dislike list',
  likeDislike_detail: 'Like dislike detail',
  likeDislike_edit: 'Like dislike edit',
  likeDislike_new: 'Like dislike new',
  quiz_list: 'Poll list',
  quiz_detail: 'Poll detail',
  quiz_edit: 'Poll edit',
  quiz_new: 'Poll new',
  quizQuestion_new: 'Quiz question new',
  quizQuestion_edit: 'Quiz question edit',
  oneQuestion_list: 'Zoznam - jedna otázka',
  oneQuestion_detail: 'Detail - jedna otázka',
  oneQuestion_edit: 'Editácia - jedna otázka',
  oneQuestion_new: 'Nový - jedna otázka',
  oneQuestionQuestion_new: 'Nová otázka jednej otázky',
  oneQuestionQuestion_edit: 'Editácia otázky jednej otázky',
  historical_dashboard: 'Historical dashboard',
  beUserKpi_list: 'KPI management',
  beUserKpi_edit: 'Edit KPI',
  kpi_comparison: 'Compare KPI',
  performance_kpi_settings: 'KPI Settings',
  performance_product: 'Product Performance',
  performance_department: 'Department Performance',
  historical_performance: 'Historical Performance',
  performance_coefficient: 'Scales settings',
  performance_weights: 'Weights settings',
  edonProduct_list: 'Products',
  edonProduct_new: 'New product',
  edonProduct_edit: 'Edit product',
  beUser_list: 'BE user list',
  beUser_edit: 'BE user edit',
  beUser_new: 'New BE user',
  feUser_list: 'Fe user list',
  feUser_edit: 'Fe user edit',
  feUser_new: 'Fe user new',
  feUserArticle_list: 'FeUser Article list',
  feUserArticle_edit: 'FeUser Article edit',
  feUserArticle_new: 'FeUser Article new',
  feUserBillingAddress_list: 'FeUser Billing Address list',
  feUserBillingAddress_edit: 'FeUser Billing Address edit',
  feUserBillingAddress_new: 'FeUser Billing Address new',
  feUserDeliveryAddress_list: 'FeUser Delivery Address list',
  feUserDeliveryAddress_edit: 'FeUser Delivery Address edit',
  feUserDeliveryAddress_new: 'FeUser Delivery Address new',
  feUserPollVote_list: 'FeUser Poll Vote list',
  hottopics_edit: 'Hot Topics - edit panel',
  hottopics_list: 'Hot Topics - list of panels',
  hottopics_new: 'Hot Topics - new panel',
  site_list: 'Site list',
  site_edit: 'Site edit',
  rubric_list: 'Rubric list',
  rubric_edit: 'Rubric edit',
  rubric_new: 'Rubric new',
  category_list: 'Category list',
  category_edit: 'Category edit',
  category_new: 'Category new',
  tag_list: 'Tag list',
  tag_edit: 'Tag edit',
  tag_new: 'Tag new',
  commonTags_list: 'Tags - List',
  geneeaTags_list: 'Geneea Tags - List',
  geneeaTag_edit: 'Geneea Tags - Edit',
  geneeaTag_detail: 'Geneea Tags - Detail',
  editorialTags_list: 'Editorial Tags - List',
  editorialTag_edit: 'Editorial Tags - Edit',
  editorialTag_detail: 'Editorial Tags - Detail',
  editorialTag_new: 'Editorial tags - new',
  themeSpecialAndSeries_list: 'Themes, specials, and series - list',
  themeSpecialAndSeries_edit: 'Themes, specials, and series - edit',
  special_detail: 'Specials - detail',
  theme_detail: 'Themes - detail',
  series_detail: 'Series - detail',
  themeSpecialAndSeries_detail: 'Themes, specials, and series - detail',
  themeSpecialAndSeries_new: 'Themes, specials, and series - new',
  department_list: 'Department list',
  department_edit: 'Department edit',
  department_new: 'Department new',
  app_performance: 'App Performance',
  scale_list: 'Scale list',
  scale_edit: 'Scale edit',
  safetyTopic_list: 'SafetyTopic list',
  safetyTopic_edit: 'SafetyTopic edit',
  safetyTopic_new: 'SafetyTopic new',
  redirect_list: 'Redirect list',
  redirect_edit: 'Redirect edit',
  redirect_new: 'Redirect new',
  redirect_detail: 'Redirect detail',
  feature_list: 'Feature list',
  feature_edit: 'Feature edit',
  articleHistory_list: 'Article history list',
  sport24_list: 'Sport tables',
  sport24_detail: 'Sport tables - detail',
  help_video_list: 'Help video list',
  userLoginAttempt_list: 'User Login Attempt list',
  tasrNews_list: 'List TASR news',
  tasrNews_detail: 'Detail TASR news',
  tasrNewsCategory_list: 'List TASR news category',
  tasrNewsCategory_edit: 'Edit TASR news category',
  tasrNewsCategory_new: 'New TASR news category',
  topicOffer_list: 'Topic offer list',
  topicOffer_detail: 'Topic offer detail',
  printTitle_list: 'List Print Titles',
  printTitle_detail: 'Detail Print Title',
  printTitle_edit: 'Edit Print Title',
  printTitle_new: 'New Print Title',
  printPublication_preview: 'Aktuálne printové vydania na webe',
  printPublication_list: 'List Print Publications',
  printPublication_detail: 'Detail Print Publication',
  printPublication_edit: 'Edit Print Publication',
  printPublication_new: 'New Print Publication',
  video_list: 'Video list',
  video_list_old: 'Video list(old)',
  video_detail: 'Video detail',
  video_detail_old: 'Video detail(old)',
  video_edit: 'Video edit',
  video_edit_old: 'Video edit(old)',
  video_new: 'Video new',
  video_new_old: 'Video new(old)',
  videoAnnotation_list: 'Annotation list',
  videoAnnotation_list_old: 'Annotation list(old)',
  videoAnnotation_detail: 'Annotation detail',
  videoAnnotation_detail_old: 'Annotation detail(old)',
  videoAnnotation_edit: 'Annotation edit',
  videoAnnotation_edit_old: 'Annotation edit(old)',
  videoAnnotation_new: 'Annotation new',
  videoAnnotation_new_old: 'Annotation new(old)',
  videoShow_list: 'Video show list',
  videoShow_detail: 'Video show detail',
  videoShow_edit: 'Video show edit',
  videoShow_new: 'Video show new',
  videoCategory_list: 'Video category list',
  videoCategory_detail: 'Video category detail',
  videoCategory_edit: 'Video category edit',
  videoCategory_new: 'Video category new',
  videoManagement: 'Video management',
  videoDashboard: 'Štatistiky videí',
  videoKpiReports: 'KPI reports',
  printArticle_list: 'List print articles',
  printArticle_edit: 'Edit print article',
  printArticle_new: 'New print article',
  eshopSubscriptionProduct_list: 'Products',
  eshopSubscriptionProduct_new: 'New Product',
  eshopSubscriptionProduct_edit: 'Edit Product',
  eshopSubscriptionOrder_list: 'Orders',
  eshopSubscriptionOrder_detail: 'Order',
  eshopSubscriptionPayment_list: 'Payments',
  eshopSubscriptionPayment_detail: 'Payment',
  eshopSubscription_list: 'Subscriptions',
  eshopSubscription_new: 'New Subscription',
  eshopSubscription_edit: 'Edit Subscription',
  eshopSubscriber_list: 'Subscribers',
  eshopHistory_list: 'History',
  eshopFeature_list: 'Eshop feature list',
  eshopFeature_edit: 'Eshop feature edit',
  eshopDashboard: 'Dashboard',
  eshopTopProductSetting_edit: 'Edit TOP Products',
  videoDailyReport: 'Editorial videos report',
  videoPublishedReport: 'Published video statistics',
  notification_detail: 'Server detail',
  notification_edit: 'Edit server',
  notification_list: 'Notifications - servers',
  notification_new: 'New server',
  permissionGroup_list: 'Permission groups',
  permissionGroup_new: 'New permission group',
  permissionGroup_detail: 'Permission group detail',
  permissionGroup_edit: 'Edit permission group',
  recipe_detail: 'Recipe detail',
  recipe_edit: 'Edit recipe',
  recipe_list: 'Recipes',
  recipe_new: 'New recipe',
  recipe_category_edit: 'Edit recipe category',
  recipe_category_list: 'Recipe categories',
  recipe_category_new: 'New recipe category',
  hp_topic_offer_list: 'Homepage topic offer',
  hp_topic_offer_list_old: 'Homepage topic offer(old)',
  woodWing_detail: ' Woodwing article detail',
  woodWing_list: 'Homepage of Woodwing articles',
  kpi_nmh: 'NMH KPI',
  kpi_nmh_old: 'Historical NMH KPI',
  kpi_nmh_settings: 'NMH KPI settings',
  gallery_detail: 'Gallery detail',
  gallery_edit: 'Edit gallery',
  gallery_list: 'Galleries',
  gallery_new: 'New gallery',
  userLogout: 'User logout',
  online_reportage_detail: 'Online reportage detail',
  online_reportage_edit: 'Edit online reportage',
  online_reportage_list: 'List of online reportages',
  online_reportage_new: 'New online reportage'
}

export default messages
