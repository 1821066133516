import moment from 'moment'
import Store from '@/store'
import { MODULE_MODULES_ONLINE_REPORTAGE } from '@/model/ValueObject/UserPermissionModules'

const buildFilterQuery = function (filter) {
  let filterQuery = ''
  if (filter.id) {
    filterQuery += '&filter_eq[id]=' + filter.id
  }
  if (filter.title) {
    filterQuery += '&filter_contains[title]=' + filter.title
  }
  if (filter.createdAtFrom) {
    const date = moment(filter.createdAtFrom).add(-1, 'hours').utc().format('YYYY-MM-DDTHH:mm:ss') + '.000Z'
    filterQuery += '&filter_gte[createdAt]=' + date
  }
  if (Store.getters.vlm) {
    if (filter.site) {
      filterQuery += '&filter_eq[site]=' + filter.site
    } else {
      const enabledSites = Store.getters['site/enabledSites'](MODULE_MODULES_ONLINE_REPORTAGE)
      filterQuery += '&filter_in[site]=' + enabledSites.map(site => site.id)
    }
  }
  return filterQuery
}

export default {
  buildFilterQuery
}
