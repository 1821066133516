import PermissionService from '@/services/PermissionService'

const onlineReportageRequiredPermissions =
  PermissionService.REQUIRED_PERMISSIONS.ONLINE_REPORTAGE_PERMISSIONS

const routes = [
  {
    path: 'onlineReportage/:id/edit',
    component: () => import('../../views/onlineReportage/vlm/OnlineReportageEditViewVlm'),
    name: 'online_reportage_edit',
    meta: {
      description: 'Online reportage edit',
      requiresAuth: true,
      requiredPermissionModules: onlineReportageRequiredPermissions.editRoute
    }
  },
  {
    path: 'onlineReportage/new',
    component: () => import('../../views/onlineReportage/vlm/OnlineReportageNewViewVlm'),
    name: 'online_reportage_new',
    meta: {
      description: 'Online reportage new',
      requiresAuth: true,
      requiredPermissionModules: onlineReportageRequiredPermissions.newRoute
    }
  },
  {
    path: 'onlineReportage/:id',
    component: () => import('../../views/onlineReportage/vlm/OnlineReportageView'),
    name: 'online_reportage_detail',
    meta: {
      description: 'Online reportage detail',
      requiresAuth: true,
      requiredPermissionModules: onlineReportageRequiredPermissions.detailRoute
    }
  },
  {
    path: 'onlineReportage',
    component: () => import('../../views/onlineReportage/vlm/OnlineReportageListViewVlm'),
    name: 'online_reportage_list',
    meta: {
      description: 'Online reportage list',
      requiresAuth: true,
      requiredPermissionModules: onlineReportageRequiredPermissions.listRoute
    }
  }
]

export default routes
