const messages = {
  list: {
    id: 'Id',
    server: 'Server',
    title: 'Online reportage title'
  },
  filter: {
    id: 'Id',
    server: 'Server',
    title: 'Online reportage title'
  },
  articleSelectReportage: {
    reportageId: 'ID',
    reportageTitle: 'Online reportage',
    selectReportageButton: 'Select Online reportage',
    selectModal: {
      title: 'Select Online reportage'
    },
    confirmModal: {
      title: 'Inserting an Online reportage',
      body1: 'You are about to insert <b>{title}.</b>',
      body2: 'Do you want to overwrite the existing <b><i>title</i></b> and <b><i>perex</i></b> of the article with <b>the title and description of the online reportage?</b >'
    }
  },
  saveReportageFirst: 'Save the reportage first',
  notOpenReportage: 'Contributions can only be added to Online reportage in the "Open" state',
  server: 'Server',
  propertiesTab: 'Reportage properties',
  manageContributionsTab: 'Manage contributions',
  title: 'Reportage name',
  position: 'Position',
  startedAt: 'Event beginning',
  isOpenLabel: 'Reportage status',
  isOpenLabelTrue: 'Open',
  isOpenLabelFalse: 'Closed',
  content: 'Contribution text',
  contentShort: 'Text',
  timestamp: 'Date and time of contribution',
  timestampShort: 'Date/time',
  filterSelect: 'Filter',
  description: 'Description',
  importantLabel: 'Important',
  importantLabelTrue: '',
  importantLabelFalse: '',
  imageReportage: 'Reportage image',
  imageContribution: 'Contribution image'
}

export default messages
